import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { LeasingComponent } from './leasing/leasing.component';
import { AmenitiesComponent } from './amenities/amenities.component';
import { ResidentsComponent } from './residents/residents.component';
import { FaqComponent } from './faq/faq.component';
import { ContactComponent } from './contact/contact.component';
import { NeighborhoodComponent } from './neighborhood/neighborhood.component';
import { PhotosComponent } from './photos/photos.component';

const routes: Routes = [

    {
        path: 'home',
        component: HomeComponent,
        data: {
            state: 'home'
        }
    },
    {
        path: 'leasing',
        component: LeasingComponent,
        data: {
            state: 'leasing'
        }
    },
    {
        path: 'amenities',
        component: AmenitiesComponent,
        data: {
            state: 'amenities'
        }
    },
    {
        path: 'residents',
        component: ResidentsComponent,
        data: {
            state: 'residents'
        }
    },
    {
        path: 'neighborhood',
        component: NeighborhoodComponent,
        data: {
            state: 'neighborhood'
        }
    },
    {
        path: 'photos',
        component: PhotosComponent,
        data: {
            state: 'photos'
        }
    },
    {
        path: 'faq',
        component: FaqComponent,
        data: {
            state: 'faq'
        }
    },
    {
        path: 'contact',
        component: ContactComponent,
        data: {
            state: 'contact'
        }
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' });

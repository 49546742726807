import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { routing } from './app.routes';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { AmenitiesComponent } from './amenities/amenities.component';
import { ContactComponent } from './contact/contact.component';
import { ResidentsComponent } from './residents/residents.component';
import { LeasingComponent } from './leasing/leasing.component';
import { FaqComponent } from './faq/faq.component';
import { ExperienceChooserComponent } from './experience-chooser/experience-chooser.component';
import { NeighborhoodComponent } from './neighborhood/neighborhood.component';
import {AccordionModule} from 'primeng/accordion';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { PhotosComponent } from './photos/photos.component';
//import { Validators, FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    FooterComponent,
    AmenitiesComponent,
    ContactComponent,
    ResidentsComponent,
    LeasingComponent,
    FaqComponent,
    ExperienceChooserComponent,
    NeighborhoodComponent,
    PhotosComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    routing,
    HttpClientModule,
    AccordionModule,
    InputTextModule,
    InputTextareaModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

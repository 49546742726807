import { Component, OnInit, HostListener, Inject  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.less']
})
export class NavigationComponent implements OnInit {
  expanded = false;

  constructor(@Inject(DOCUMENT) private document: any) { }

  ngOnInit(){
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 100) {
       let element = document.getElementById('navBar');
       element.classList.add('sticky');
     } else {
      let element = document.getElementById('navBar');
        element.classList.remove('sticky');
     }
  }

  toggleNav() {
    this.expanded = !this.expanded;
    let element = document.getElementById('body');
    element.classList.toggle('menu-open');
  }
}

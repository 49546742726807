import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-residents',
  templateUrl: './residents.component.html',
  styleUrls: ['./residents.component.less']
})
export class ResidentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-neighborhood',
  templateUrl: './neighborhood.component.html',
  styleUrls: ['./neighborhood.component.less']
})
export class NeighborhoodComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
//import { FormControl } from '@angular/forms';



@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less']
})
export class ContactComponent implements OnInit {

  //name = new FormControl('');
  

  constructor() { }

  ngOnInit() {
  }

/*this.contactForm = this.fb.group({
  name: ['', Validators.required],

});
submitForm() {
  const body = new HttpParams()
    .set('form-name', 'contact')
  .append('name', this.contactForm.value.name)
  
  ...the rest of your contact forms form groups
  this.http.post('/', body.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).subscribe(
    res => { }
  );
}*/

}

import { Component, OnInit, AfterViewInit} from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { WOW } from 'wowjs/dist/wow.min';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'creekwalk-commons';
  constructor() { }

  ngAfterViewInit() {
    new WOW().init();
  }

  ngOnInit() {

  }

}
